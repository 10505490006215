import PropTypes from "prop-types"
import styled from "styled-components"

const Top = styled.a`
  color: ${({ theme, color }) => {
    if (color === "black" || color === "secondary") return theme.colors.primary
    else return theme.colors.secondary
  }};
  padding-top: 1.3em;
  display: inline-block; /* or block */
  position: relative;
  border-color: ${({ theme, color }) => {
    if (color === "black" || color === "secondary") return theme.colors.primary
    else return theme.colors.secondary
  }};
  text-decoration: none;
  transition: all 0.3s ease-out;
  position: fixed;
  bottom: 30px;
  right: 25px;
  font-size: 2rem;
  text-align: center;
  :before {
    content: "▲";
    font-size: 0.9em;
    position: absolute;
    top: 0;
    left: 50%;
    margin-left: -0.7em;
    border: solid 0.13em white;
    border-radius: 10em;
    width: 1.4em;
    height: 1.4em;
    line-height: 1em;
    border-color: inherit;
    transition: transform 0.5s ease-in;
  }
  :hover {
    color: ${({ theme, color }) => {
      if (color === "black") return theme.colors.backgroundSecondary
      else return theme.colors.background
    }};
    border-color: ${({ theme, color }) => {
      if (color === "black") return theme.colors.backgroundSecondary
      else return theme.colors.background
    }};
  }
  :hover:before {
    transform: rotate(360deg);
  }
`

const BackToTop = ({ color }) => {
  return <Top href="#" color={color} />
}

BackToTop.propTypes = {
  color: PropTypes.string.isRequired,
}

export default BackToTop
