import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const Logo = ({ color }) => {
  const data = useStaticQuery(graphql`
    query {
      allFile(filter: { name: { regex: "/logo/" } }) {
        nodes {
          childImageSharp {
            fixed(width: 80) {
              base64
              height
              src
              srcSet
              width
              originalName
            }
          }
        }
      }
    }
  `)
  let logo
  if (color === "primary") {
    logo = data.allFile.nodes.filter((i) =>
      i.childImageSharp.fixed.originalName.match(/first/)
    )[0].childImageSharp.fixed
  } else if (color === "secondary") {
    logo = data.allFile.nodes.filter((i) =>
      i.childImageSharp.fixed.originalName.match(/second/)
    )[0].childImageSharp.fixed
  } else {
    logo = data.allFile.nodes.filter((i) =>
      i.childImageSharp.fixed.originalName.match(/third/)
    )[0].childImageSharp.fixed
  }
  return <Img fixed={logo} alt="E.Pantazis logo" />
}

Logo.propTypes = {
  //   size: PropTypes.string,
  color: PropTypes.string,
}

export default Logo
