import { useState, useEffect, useContext } from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import Helmet from "react-helmet"
import styled from "styled-components"
import { motion, useAnimation } from "framer-motion"

import Context from "../context"
import { detectMobileAndTablet, isSSR, nextPageWork } from "../utils/"
import ContentWrapper from "../styles/ContentWrapper"
import Logo from "./logo"
import Sidebar from "./sidebar"
import Navbar from "./navbar"

const StyledHeader = motion(styled.header`
  width: 100%;
  background-color: ${({ theme, color }) => theme.colors[color]};
  padding: 25px 10px 10px 10px;
  position: fixed;
  z-index: 1;
`)

const StyledContentWrapper = styled(ContentWrapper)`
  && {
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-gap: 50px;
    align-items: center;
  }
  #logo {
    grid-column-start: span 2;
    width: max-content;
  }
  h3 {
    grid-column: 5 / span 2;
    color: #000000;
  }
  nav {
    grid-column: 7 / span 6;
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.lg}) {
    && {
      display: flex;
      justify-content: space-between;
      padding: 0 15px;
    }
    h3 {
      margin: 0;
    }
  }
`

// https://css-tricks.com/hamburger-menu-with-a-side-of-react-hooks-and-styled-components/
const StyledBurger = styled.button`
  z-index: 12;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 2rem;
  height: 2rem;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;

  &:focus {
    outline: none;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
    display: none;
  }

  div {
    width: 2rem;
    height: 0.25rem;
    background-color: ${({ theme, color }) => {
      if (color === "black" || color === "secondary")
        return theme.colors.primary
      else return theme.colors.secondary
    }};
    border-radius: 0.625rem;
    transition: all 0.3s ease-in-out;
    position: relative;
    transform-origin: 1px;

    :first-child {
      transform: ${({ open }) => (open ? "rotate(45deg)" : "rotate(0)")};
    }

    :nth-child(2) {
      opacity: ${({ open }) => (open ? "0" : "1")};
      transform: ${({ open }) => (open ? "translateX(20px)" : "translateX(0)")};
    }

    :nth-child(3) {
      transform: ${({ open }) => (open ? "rotate(-45deg)" : "rotate(0)")};
    }
  }
`

const Header = ({ route, color, nextProject }) => {
  const { isIntroDone } = useContext(Context).state
  const [open, setOpen] = useState(false)
  const [windowWidth, setWindowWidth] = useState(0)
  const nextWork = nextPageWork(route)

  useEffect(() => {
    let handleWindowSizeChange
    // if (isSSR) is necessary to prevent error during the gatsby build
    if (!isSSR) {
      handleWindowSizeChange = () => setWindowWidth(window.innerWidth)
      // set initial innerWidth when component mounts
      setWindowWidth(window.innerWidth)
    }
    // Add event listener to update windowWidth in state
    window.addEventListener("resize", handleWindowSizeChange)
    return () => window.removeEventListener("resize", handleWindowSizeChange)
  }, [windowWidth])

  // Required for animation - start after the splashScreen sequence is done
  const controls = useAnimation()
  useEffect(() => {
    if (isIntroDone)
      controls.start({ opacity: 1, y: 0, transition: { delay: 0.2 } })
  }, [isIntroDone, controls])

  let navigation
  if (detectMobileAndTablet(windowWidth)) {
    navigation = (
      <>
        <StyledBurger
          aria-controls="sidebar"
          open={open}
          color={color}
          onClick={() => setOpen(!open)}
        >
          <div />
          <div />
          <div />
        </StyledBurger>
        <Sidebar
          id="sidebar"
          open={open}
          setOpen={setOpen}
          color={color}
          nextWork={nextWork}
          nextProject={nextProject}
        />
      </>
    )
  } else {
    navigation = (
      <Navbar color={color} nextWork={nextWork} nextProject={nextProject} />
    )
  }

  return (
    <StyledHeader
      color={color}
      initial={{ opacity: 0, y: -10 }}
      animate={controls}
    >
      {/* add blur class to body when sidebar is opened */}
      <Helmet bodyAttributes={{ class: open ? "blur" : "" }} />
      <StyledContentWrapper>
        <Link id="logo" to="/" aria-label="home">
          <Logo
            color={
              color === "black"
                ? "primary"
                : color === "secondary"
                ? "secondary"
                : "other"
            }
            size="2rem"
          />
        </Link>
        {nextWork ? (
          <h3>{route.charAt(0).toUpperCase() + route.slice(1)}</h3>
        ) : color === "secondary" ? (
          <h3>Codes&Scripts</h3>
        ) : null}
        {navigation}
      </StyledContentWrapper>
    </StyledHeader>
  )
}

Header.propTypes = {
  route: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
  nextProject: PropTypes.object,
}

export default Header
