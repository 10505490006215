import PropTypes from "prop-types"
import styled from "styled-components"

import ContentWrapper from "../styles/ContentWrapper"

const StyledFooter = styled.footer`
  width: 100%;
  background-color: ${({ theme, color }) => theme.colors[color]};
  padding: 50px 25px;
  color: ${({ theme, color }) => {
    if (color === "research") return theme.colors.black
  }};
  strong {
    color: ${({ theme, color }) => {
      if (color === "black" || color === "secondary")
        return theme.colors.primary
      else return theme.colors.secondary
    }};
  }
`

const StyledContentWrapper = styled(ContentWrapper)`
  && {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 0 25px;
    .footer-links {
      /* Adjust width of links wrapper accordingly */
      width: 10rem;
      display: flex;
      justify-content: center;
      @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
        width: 15rem;
      }
    }
    @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
      padding: 0;
    }
  }
`

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-gap: 50px;
  width: 100%;
  font-size: 0.875rem;
  @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    display: flex;
    flex-direction: column;
    span {
      display: none;
    }
  }
`

const Footer = ({ color }) => (
  <StyledFooter color={color}>
    <StyledContentWrapper>
      <Grid>
        <div>
          <strong>Copyright & Copy:</strong>
          <p>Evangelos Pantazis</p>
          <p>2019</p>
        </div>
        <span></span>
        <div>
          <strong>Designed by:</strong>
          <p>
            Vague
            <br />&<br />
            MataoOka
          </p>
        </div>
        <span></span>
        <div>
          <strong>Developed by:</strong>
          <p>
            Vague
            <br />&<br />
            m1xal10s
          </p>
        </div>
      </Grid>
    </StyledContentWrapper>
  </StyledFooter>
)

Footer.propTypes = {
  color: PropTypes.string.isRequired,
}

export default Footer
