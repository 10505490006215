import Theme from "../styles/Theme"
import { useState, useEffect } from "react"
import { useMediaQuery } from "react-responsive"

export const Desktop = ({ children }) => {
  const isDesktop = useMediaQuery({ minWidth: 992 })
  return isDesktop ? children : null
}
export const Tablet = ({ children }) => {
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 })
  return isTablet ? children : null
}
export const Mobile = ({ children }) => {
  const isMobile = useMediaQuery({ maxWidth: 767 })
  return isMobile ? children : null
}
export const Default = ({ children }) => {
  const isNotMobile = useMediaQuery({ minWidth: 768 })
  return isNotMobile ? children : null
}

export const useMedia = (queries, values, defaultValue) => {
  const mediaQueryLists =
    typeof window !== `undefined`
      ? queries.map((q) => window.matchMedia(q))
      : null
  const getValue = () => {
    if (typeof window !== `undefined`) {
      const index = mediaQueryLists.findIndex((mql) => mql.matches)
      return typeof values[index] !== "undefined" ? values[index] : defaultValue
    }
  }
  const [value, setValue] = useState(getValue)

  useEffect(() => {
    if (typeof window !== `undefined`) {
      const handler = () => setValue(getValue)
      mediaQueryLists.forEach((mql) => mql.addListener(handler))
      return () => mediaQueryLists.forEach((mql) => mql.removeListener(handler))
    }
  }, [])

  return value
}

// breakpoints.lg needs to be cleaned and parsed since it is a string like '1200px'
export const detectMobileAndTablet = (windowWidth) =>
  windowWidth < parseInt(Theme.breakpoints.lg.match(/\d+/gi).join(""))

// detect server-side-rendering to fix bugs while gatsby build
export const isSSR = typeof window === "undefined"

// used to parse the publication date of medium articles
export const parseDate = (date) => {
  const year = date.substring(0, 4)
  const month = date.substring(5, 7)
  const day = date.substring(8, 10)

  switch (month) {
    case "01":
      return day + " January " + year
    case "02":
      return day + " February " + year
    case "03":
      return day + " March " + year
    case "04":
      return day + " April " + year
    case "05":
      return day + " May " + year
    case "06":
      return day + " June " + year
    case "07":
      return day + " July " + year
    case "08":
      return day + " August " + year
    case "09":
      return day + " September " + year
    case "10":
      return day + " October " + year
    case "11":
      return day + " November " + year
    case "12":
      return day + " December " + year
    default:
      return "No publication date"
  }
}

export const colorPicker = (route) => {
  switch (route) {
    case "research":
    case "teaching":
    case "practice":
    case "sandbox":
    case "codes&scripts":
      return "secondary"
    default:
      return "black"
  }
}

export const nextPageWork = (route) => {
  switch (route) {
    case "research":
      return "Teaching"
    case "teaching":
      return "Practice"
    case "practice":
      return "Sandbox"
    case "sandbox":
      return "Research"
    default:
      return null
  }
}
