module.exports = {
  colors: {
    primary: "#FFF",
    secondary: "#00F",
    black: "#000",
    mobileMenu: "#000000a6",
    textPrimary: "#FFF",
    textSecondary: "#00F",
    background: "#000",
    backgroundSecondary: "#00F",
    research: "#FFF",
    work: "#a6a6a6",
    teaching: "#666666",
  },
  breakpoints: {
    // xs: "480px",
    // sm: "768px",
    // md: "992px",
    // lg: "1200px",
    xs: "360px",
    sm: "768px",
    md: "992px",
    lg: "1200px",
  },
  fonts: {
    primary: "Poppins, Arial, sans-serif",
    secondary: "JetBrains Mono, Arial, sans-serif",
  },
  borderRadius: "1rem",
  // pageWidth: "62.5rem",
  pageWidth: "100%",
  headerHeight: "6.25rem",
  footerHeight: "7.5rem",
}
