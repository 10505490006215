module.exports = {
  author: "Evangelos Pantazis",
  siteTitle: "Evangelos Pantazis",
  siteShortTitle: "EP_XYZ", // Used as logo text in header, footer, and splash screen
  siteDescription: "Evangelos Pantazis Work",
  siteUrl: "https://evangelospantazis.xyz/",
  siteLanguage: "en_US",
  siteIcon: "src/favicon.png", // Relative to gatsby-config file

  splashScreen: false, // Set this to true if you want to use the splash screen

  // You can create your own Medium feed with this rss to json converter: https://rss2json.com/
  // To access your Medium RSS feed, just replace this url with your username: https://medium.com/feed/@{yourname}
  mediumRssFeed:
    "https://api.rss2json.com/v1/api.json?rss_url=https%3A%2F%2Fmedium.com%2Ffeed%2F%40konstantinmuenster",
  shownArticles: 3,

  // There are icons available for the following platforms:
  // Medium, GitHub, LinkedIn, XING, Behance
  socialMedia: [
    {
      name: "Linkedin",
      url: "https://www.linkedin.com/in/konstantin-muenster/",
    },
    {
      name: "Medium",
      url: "https://konstantinmuenster.medium.com/",
    },
    {
      name: "Github",
      url:
        "https://github.com/konstantinmuenster/gatsby-starter-portfolio-minimal",
    },
    {
      name: "Behance",
      url: "https://www.behance.net/konstanmnster",
    },
  ],

  navLinks: {
    menu: [
      {
        name: "Work",
        url: "/#work",
      },
      {
        name: "Projects",
        url: "/#projects",
      },
      {
        name: "Bio",
        url: "/#bio",
      },
      {
        name: "Publications",
        url: "/#publications",
      },
      {
        name: "Contact",
        url: "/#contact",
      },
    ],
    // button: {
    //   name: "",
    //   url: "",
    // },
  },

  footerLinks: [
    {
      name: "Privacy",
      url: "/privacy",
    },
    {
      name: "Imprint",
      url: "/imprint",
    },
  ],
}
