import PropTypes from "prop-types"
import styled from "styled-components"
import { Link } from "gatsby"
import Scrollspy from "react-scrollspy"

import config from "../config/"

const { navLinks } = config

const StyledNav = styled.nav`
  display: none;
  @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-gap: 50px;
    align-items: center;
    background-color: ${({ theme, color }) => theme.colors[color]};
    a {
      color: ${({ theme }) => theme.colors.primary};
    }
  }
  .nav-link {
    width: max-content;
    font-size: 1rem;
    font-weight: 700;
    font-family: ${({ theme }) => theme.fonts.secondary};
    color: ${({ theme, color }) => {
      if (color === "black" || color === "secondary")
        return theme.colors.primary
      else return theme.colors.secondary
    }};
    position: relative;
    padding: 0;
    &:hover,
    &.is-current {
      color: ${({ theme, color }) => {
        if (color === "black") return theme.colors.backgroundSecondary
        else return theme.colors.background
      }};
    }
    &::before {
      transition: 200ms ease-out;
      height: 0.1563rem;
      content: "";
      position: absolute;
      background-color: ${({ theme, color }) => {
        if (color === "black" || color === "secondary")
          return theme.colors.primary
        else return theme.colors.secondary
      }};
      width: 0%;
      bottom: -0.125rem;
    }
    &:hover::before,
    &.is-current::before {
      background-color: ${({ theme, color }) => {
        if (color === "black") return theme.colors.secondary
        else return theme.colors.background
      }};
      width: 100%;
    }
    &.work {
      grid-column: 3 / span 2;
      &.back {
        grid-column: 6 / span 1;
      }
    }
    &.project,
    &.sandbox {
      grid-column-end: span 4;
      &.back {
        grid-column: 6 / span 1;
      }
    }
  }
  .cta-btn {
    width: auto;
    height: auto;
    font-weight: 700;
    border-radius: ${({ theme }) => theme.borderRadius};
    border: 0.125rem solid ${({ theme }) => theme.colors.primary};
    background: ${({ theme }) => theme.colors.background};
    transition: 20ms ease-out;
    font-size: 1rem;
    padding: 0.5rem 1.5rem;
    margin: 0;
    &:hover {
      background: ${({ theme }) => theme.colors.primary};
      color: #ffffff;
    }
  }
`

const Navbar = ({ color, nextWork, nextProject }) => {
  const { menu, button } = navLinks
  const MainNav = () =>
    menu.map(({ name, url }, key) => {
      return (
        <Link className="nav-link" key={key} to={url}>
          {name}
        </Link>
      )
    })
  return (
    <StyledNav color={color}>
      {nextWork ? (
        <>
          <Link className="nav-link work" to={`/${nextWork.toLowerCase()}`}>
            Next: {nextWork}
          </Link>
          <Link className="nav-link work back" to="/#work">
            Back
          </Link>
        </>
      ) : nextProject ? (
        <>
          <Link className="nav-link project" to={nextProject.fields.slug}>
            Next Project: {nextProject.frontmatter.title}
          </Link>
          <Link className="nav-link project back" to="/#projects">
            Back
          </Link>
        </>
      ) : color === "secondary" ? (
        <>
          <span className="nav-link sandbox"></span>
          <Link className="nav-link sandbox back" to="/sandbox">
            Back
          </Link>
        </>
      ) : color !== "black" ? (
        <>
          <span className="nav-link project"></span>
          <Link className="nav-link project back" to="/#projects">
            Back
          </Link>
        </>
      ) : (
        <Scrollspy
          items={["work", "projects", "bio", "publications", "contact"]}
          currentClassName="is-current"
          componentTag={MainNav}
          offset={-50}
        />
      )}

      {button && (
        <Link className="cta-btn" to={button.url}>
          {button.name}
        </Link>
      )}
    </StyledNav>
  )
}

Navbar.propTypes = {
  color: PropTypes.string.isRequired,
  nextWork: PropTypes.string,
  nextProject: PropTypes.object,
}

export default Navbar
